import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function LinearFeatures() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, -2, -3, -4, -5, -6, -7, -8, -9];
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = thirdNumber*firstNumber;
      let fifthNumber = thirdNumber*secondNumber;
      let sixthNumber = -firstNumber*secondNumber;
      let sign1 = " ";
      let sign2 = "+";
      let sign3 = " ";
      let sign4 = " ";
      let sign5 = " ";
      let sign6 = " ";

      if(firstNumber < 0) {
        firstNumber = Math.abs(firstNumber);
        sign1 = "-";
      }

      if(secondNumber < 0) {
        secondNumber = Math.abs(secondNumber);
        sign2 = "-";
      }

      if(thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign3 = "-";
      }

      if(fourthNumber < 0) {
        fourthNumber = Math.abs(fourthNumber);
        sign4 = "-";
      }

      if(fifthNumber < 0) {
        fifthNumber = Math.abs(fifthNumber);
        sign5 = "-";
      }
      if(sixthNumber < 0) {
        sixthNumber = Math.abs(sixthNumber);
        sign6 = "-";
      }

      generatedProblem.push({
        key: `${step}-lf`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        sign1: sign1,
        sign2: sign2,
        sign3: sign3,
        sign4: sign4,
        sign5: sign5,
        sign6: sign6
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.sign1} ${equation.firstNumber} x ${equation.sign2} ${equation.secondNumber} y = ${equation.sign3} ${equation.thirdNumber}$`,
      answer: (
        <span>
        <span>{`$x-int = \\left( ${equation.sign4} ${displayFraction(equation.thirdNumber, equation.firstNumber)}, 0 \\right) $`}</span>
        <br/>
        <span>{`$y-int = \\left( 0, ${equation.sign5} ${displayFraction(equation.thirdNumber, equation.secondNumber)} \\right) $`}</span>
        <br/>
        <span>{`$slope = ${equation.sign6} ${displayFraction(equation.firstNumber, equation.secondNumber)}$`}</span>
        <br/>
        </span>
      )
    };
  }

  function helpText() {
    return `
      $$ -4x + 3y = 5 $$    
      \
      $$ x - intercept: \\hspace{3mm} y = 0 $$
      \
      $$ -4x + 3(0) = 5 $$
      \
      $$ -4x = 5 $$
      \
      $$ \\dfrac{-4x = 5}{-4} $$
      \
      $$ \\dfrac{-4x}{-4} = \\dfrac{5}{-4} $$
      \
      $$ x = - \\frac{5}{4} $$
      \
      $$ \\left(- \\frac{5}{4}, 0 \\right) $$
      \
      $$ y - intercept: \\hspace{3mm} x = 0 $$
      \
      $$ -4(0) + 3y = 5 $$
      \
      $$ 3y = 5 $$
      \
      $$ \\dfrac{3y = 5}{3} $$
      \
      $$ \\dfrac{3y}{3} = \\dfrac{5}{3} $$
      \
      $$ y = \\frac{5}{3} $$
      \
      $$ \\left(0, \\frac{5}{3} \\right) $$
      \
      $$ Slope: \\hspace{3mm} m = \\frac{y_{2} - y_{1}}{x_{2} - x_{1}} $$
      \
      $$ \\dfrac{(\\frac{5}{3}) - (0)}{(0) - (- \\frac{5}{4})} $$
      \
      $$ m = \\frac{\\frac{5}{3}}{\\frac{5}{4}} $$
      \
      $$ m = \\dfrac{5}{3} \\cdot \\dfrac{4}{5} $$
      \
      $$ m = \\dfrac{5 \\cdot 4}{3 \\cdot 5} $$
      \
      $$ m = \\frac{4}{3} $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Linear Features"}
        instructions={"Compute the x-int, y-int, and slope."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Features Help", helpTextFunc: helpText}}
      />
    </div>
  );
}