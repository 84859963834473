import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function CalculusExtrema() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9];
    const evennums = [2, 4, 6, 8, 10];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = evennums[Math.floor(Math.random() * evennums.length)];
      let secondNumber = nums[Math.floor(Math.random() * nums.length)];
      let thirdNumber = nums[Math.floor(Math.random() * nums.length)];
      let fourthNumber = nums[Math.floor(Math.random() * nums.length)];
      let result1 = 3 * firstNumber;
      let result2 = result1 * (secondNumber + thirdNumber);
      let result3 = result2 / 2;
      let result4 = result1 * secondNumber * thirdNumber;
      let result5 = firstNumber * Math.pow(secondNumber, 3) - result3 * Math.pow(secondNumber, 2) + result4 * secondNumber + fourthNumber;
      let result6 = firstNumber * Math.pow(thirdNumber, 3) - result3 * Math.pow(thirdNumber, 2) + result4 * thirdNumber + fourthNumber;

      generatedProblem.push({
        key: `${step}-pc`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        result1: result1,
        result2: result2,
        result3: result3,
        result4: result4,
        result5: result5,
        result6: result6
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    // Determine whether (thirdNumber, result6) is the max or min
    let max, min;
    if (equation.result6 > equation.result5) {
      max = `(${equation.thirdNumber}, ${equation.result6})`;
      min = `(${equation.secondNumber}, ${equation.result5})`;
    } else {
      max = `(${equation.secondNumber}, ${equation.result5})`;
      min = `(${equation.thirdNumber}, ${equation.result6})`;
    }

    return {
      key: equation.key,
      equation: `$ y(x) = ${equation.firstNumber} x^{3} - ${equation.result3} x^{2} + ${equation.result4} x + ${equation.fourthNumber} $`,
      answer: `$ \\text{Minimum} = ${min} \\; \\; \\text{Maximum} = ${max} $`
    };
  }

  function helpText() {
    return `
      $$ y(x) = 6x^{3} - 81x^{2} + 252x + 9 $$    
      \
      $$ \\dfrac{d}{dx} \\Bigl( y(x) = 6x^{3} - 81x^{2} + 252x + 9 \\Bigr) $$
      \
      $$ \\dfrac{d}{dx} \\Bigl( y(x) \\Bigr) = \\dfrac{d}{dx} \\Bigl( 6x^{3} - 81x^{2} + 252x + 9 \\Bigr) $$
      \
      $$ y'(x) = \\dfrac{d}{dx} \\Bigl( 6x^{3} \\Bigr) - \\dfrac{d}{dx} \\Bigl( 81x^{2} \\Bigr) + \\dfrac{d}{dx} \\Bigl( 252x \\Bigr) + \\dfrac{d}{dx} \\Bigl( 9 \\Bigr) $$
      \
      $$ y'(x) = 18x^{2} - 162x + 252 + 0 $$
      \
       $$ y'(x) = 18x^{2} - 162x + 252 $$
      \
      $$ y'(x) = 0 $$
      \
      $$ 18x^{2} - 162x + 252 = 0 $$
      \
      $$ \\dfrac{18x^{2} - 162x + 252 = 0}{18} $$
      \
      $$ \\dfrac{18x^{2} - 162x + 252}{18} = \\dfrac{0}{18} $$
      \
      $$ \\dfrac{18 \\cdot (x^{2} - 9x + 14)}{18} = 0 $$
      \
      $$ x^{2} - 9x + 14 = 0 $$
      \
      $$ (x - 2)(x - 7) = 0 $$
      \
      $$ x - 2 = 0 $$
      \
      $$ +2 \\hspace{7mm} +2 $$
      \
      $$ x = 2 $$
      \
      $$ x - 7 = 0 $$
      \
      $$ +7 \\hspace{7mm} +7 $$
      \
      $$ x = 7 $$
      \
      $$ y(2) = 6(2)^{3} - 81(2)^{2} + 252(2) + 9 $$
      \
      $$ y(2) = 48 - 324 + 504 + 9 $$
      \
      $$ y(2) = 237 $$
      \
      $$ y(2) = 6(7)^{3} - 81(7)^{2} + 252(7) + 9 $$
      \
      $$ y(7) = 2058 - 3969 + 1764 + 9 $$
      \
      $$ y(7) = -138 $$
      \
      $$ 237 > -138 $$
      \
      $$ y(2) > y(7) $$
      \
      $$ \\text{Minimum} = (7, -138) \\; \\; \\text{Maximum} = (2, 237) $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Calculus Extrema"}
        instructions={"Find all Relative Maximums and Minimums."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{ title: "Extrema Help", helpTextFunc: helpText }}
      />
    </div>
  );
}
