import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function LinearFeatures() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  // Declare these variables at the top level so they can be accessed by both functions
  let Left, Right, Trapezoid, MidpointSum;

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, -2, -3, -4, -5, -6, -7, -8, -9];
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random() * nums.length)];
      let secondNumber = nums[Math.floor(Math.random() * nums.length)];
      let thirdNumber = nums[Math.floor(Math.random() * nums.length)];
      let fourthNumber = thirdNumber * firstNumber;
      let fifthNumber = thirdNumber * secondNumber;
      let sixthNumber = -firstNumber * secondNumber;
      let sign1 = " ";
      let sign2 = "+";
      let sign3 = " ";
      let sign4 = " ";
      let sign5 = " ";
      let sign6 = " ";

      if (firstNumber < 0) {
        firstNumber = Math.abs(firstNumber);
        sign1 = "-";
      }

      if (secondNumber < 0) {
        secondNumber = Math.abs(secondNumber);
        sign2 = "-";
      }

      if (thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign3 = "-";
      }

      if (fourthNumber < 0) {
        fourthNumber = Math.abs(fourthNumber);
        sign4 = "-";
      }

      if (fifthNumber < 0) {
        fifthNumber = Math.abs(fifthNumber);
        sign5 = "-";
      }
      if (sixthNumber < 0) {
        sixthNumber = Math.abs(sixthNumber);
        sign6 = "-";
      }

      generatedProblem.push({
        key: `${step}-lf`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        sign1: sign1,
        sign2: sign2,
        sign3: sign3,
        sign4: sign4,
        sign5: sign5,
        sign6: sign6,
      });
    }

    // Initialize variables
    let a = 2;
    let b = 7;
    let n = 4;
    let dx = (b - a) / n;
    let x = [];
    for (let i = a; i <= b; i += dx) {
      x.push([i]);
    }
    let s = x.length;

    // Fill the array with calculations
    for (let i = 0; i < s; i++) {
      x[i][1] = Math.pow(x[i][0], 3) - 5 * Math.pow(x[i][0], 2) - 6 * x[i][0] + 8;
      x[i][2] = x[i][1];
      x[i][3] = x[i][2];
      x[i][4] = x[i][2];
      x[i][5] = x[i][2];
    }

    // Modify specific elements in the array
    for (let i = 1; i < s - 1; i++) {
      x[i][5] = 2 * x[i][5];
    }

    x[0][4] = 0;
    x[s - 1][3] = 0;

    // Calculate sums for the results
    Left = dx * x.reduce((sum, row) => sum + row[3], 0);
    Right = dx * x.reduce((sum, row) => sum + row[4], 0);
    Trapezoid = (dx / 2) * x.reduce((sum, row) => sum + row[5], 0);

    // Midpoint Riemann Sum calculation
    MidpointSum = 0;
    for (let i = 0; i < s - 1; i++) {
      let midpoint = (x[i][0] + x[i + 1][0]) / 2; // Calculate midpoint of each subinterval
      let midpointValue = Math.pow(midpoint, 3) - 5 * Math.pow(midpoint, 2) - 6 * midpoint + 8; // Polynomial evaluation at midpoint
      MidpointSum += midpointValue;
    }
    MidpointSum *= dx; // Multiply sum by dx to get the total midpoint sum

    let formattedProblemList = [];
    generatedProblem.forEach((problem) => {
      formattedProblemList.push(equationFormatter(problem));
    });
    setFormattedProblems(formattedProblemList);
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: (
        <span>
            <span>{`$ \\int_{2}^{7} x^{3} - 5x^{2} - 6x + 8 \\, dx \\, \\, \\, \\, \\, \\, n = 4 $`}</span>
        </span>
      ),
      
      answer: (
        <span>
          <span>{`$ \\text{Left} = ${Left} $`}</span>
          <br />
          <span>{`$ \\text{Right} = ${Right} $`}</span>
          <br />
          <span>{`$ \\text{Trapezoid} = ${Trapezoid} $`}</span>
          <br />
          <span>{`$ \\text{Midpoint} = ${MidpointSum} $`}</span>
          <br />
        </span>
      ),
    };
  }

  function helpText() {
    return `
      $$ -4x + 3y = 5 $$    
      \
      $$ m = \\frac{4}{3} $$
      `;
  }

  return (
    <div>
      <EquationLayout
        title={"Calculus Reimann Sums"}
        instructions={"Compute the Left, Right, Midpoint, and Trapezoid Reimann Sums to compute approximate the Integral."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{ title: "Riemann Sums Help", helpTextFunc: helpText }}
      />
    </div>
  );
}
