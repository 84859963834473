import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { randomNumFromSet } from '../../../../utils/mathHelpers';

export default function CalculusDefiniteIntegral() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums1 = [2, 3, 4, 5, 6, 7];
    const nums2 = [2, 3, 4];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = randomNumFromSet(nums1);
      let secondNumber = randomNumFromSet(nums2) + firstNumber;
      let thirdNumber = randomNumFromSet(nums1);
      let fourthNumber = randomNumFromSet(nums1);
      let result1 = thirdNumber/(fourthNumber+1);
      let result2 = Math.pow(secondNumber,fourthNumber+1);
      let result3 = Math.pow(firstNumber,fourthNumber+1);
      let result4 = result1*(result2 - result3);

      generatedProblem.push({
        key: `${step}-cdi`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        result4: Math.round(result4 * 100) / 100,
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$ \\int_{${equation.firstNumber}}^{${equation.secondNumber}} ${equation.thirdNumber} x^{${equation.fourthNumber}}\\text{ } dx $`,
      answer: `$ ${equation.result4} $`
    }
  }

  function helpText() {
    return `
        $$ \\int_{4}^{6} 5 x^{2} dx $$
        \
        $$ = \\frac{5 (x^{2 + 1})}{2 + 1} \\hspace{3mm} \\bigg| _{4} ^{6} $$
        \
        $$ = \\frac{5 x^{3}}{3} \\hspace{3mm} \\bigg| _{4} ^{6} $$
        \
        $$ = \\frac{5}{3} \\cdot x^{3} \\hspace{3mm} \\bigg| _{4} ^{6} $$
        \
        $$ = \\left( \\frac{5}{3} (6)^{3} \\right) - \\left( \\frac{5}{3} (4)^{3} \\right) $$
        \
        $$ = \\left( \\frac{5}{3} \\cdot 216\\right) - \\left( \\frac{5}{3} \\cdot 64 \\right) $$
        \
        $$ = \\frac{5}{3} \\cdot (216 - 64) $$
        \
        $$ = \\frac{5}{3} \\cdot (152) $$
        \
        $$ = \\frac{760}{3} $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Calculus Definite Integral"}
        instructions={"Compute the integral."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Definite Integral Help", helpTextFunc: helpText}}
      />
    </div>
  );
}